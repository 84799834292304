import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import emotion from "../../Assets/Projects/emotion.png";
import movie from "../../Assets/Projects/movie.png";
import chatify from "../../Assets/Projects/chatify.png";
import toast from "../../Assets/Projects/toast.png";
import neural from "../../Assets/Projects/neural.png";
import videochat from "../../Assets/Projects/videochat.png";
import paint from "../../Assets/Projects/paint.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Work</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
                imgPath={toast}
                isBlog={true}
                title="React Native Toast Module"
                description="A simple, modern, React Native Toast module that supports gesture handling, lotties, message queuing and more!"
                ghLink="https://medium.com/p/53017dd832b7#234c-deb7cc6e2c21"

            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={neural}
              isBlog={false}
              title="Deep learning GAN"
              description="A deep learning GAN image processor that was feed pictures of myself. The purpose of this repo was to explore my and test my knowledge with machine learning"
              ghLink="https://github.com/subhyde/DeepLearning-AI-Images"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={videochat}
              isBlog={false}
              title="Video Chat"
              description="I wanted to create a light way to video chat with friends via browser without the need to download an executable. With the usage of sockets i was able to connect multiple users together in a single room with screen sharing functionality"
              ghLink="https://github.com/subhyde/video-chat-screenshare"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={paint}
              isBlog={false}
              title="Paint Application"
              description="A paint application built with React. This paint clone allows a user to create a custom grid size, a colour picker and a bucket to fill in the desired space!"
              ghLink="https://github.com/subhyde/MS-paint-clone"
             demoLink="https://elegant-colden-f9b1aa.netlify.app/"  
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={movie}
              isBlog={false}
              title="Movie DB"
              description="A very simple restful API React app. With real time searching and page re-rendering. You can view a movies short description and rating"
              ghLink="https://github.com/subhyde/movie_search"
              demoLink="https://brave-lichterman-858279.netlify.app/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Online Chatroom"
              description="An online anonymous chatroom that instantly connects everyone on the domain together. This projects utilizes scaledrone to easily connect users together"
              ghLink="https://github.com/subhyde/onlineIM"
              demoLink="https://subhyde.github.io/onlineIM/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Using a trained model, This program can detect age, gender, and your current emotional state in real time."
              ghLink="https://github.com/subhyde/facial-detection"
             demoLink="https://subhyde.github.io/facial-detection/"  
            />
          </Col>

      
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
